"use client";

import useMobile from "@/lib/hooks/useMobile";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { getProductData } from "@/server/action";
import { useEffect, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
const category = [
  {
    name: "Illumination Products",
    des: "Portable lighting products",
    img: "/image/home/category/5.webp",
    mImg: "/image/home/category/5-m.webp",
    // href: "/illumanation",
    // 随后台sku更换路径
    href: "/product/t5-set-760-lumen-400-meter-long-range-hunting-flashlight-set"
  },
  {
    name: "Lighting Accessories",
    des: "Portable lighting products",
    img: "/image/home/category/2.webp",
    mImg: "/image/home/category/2-m.webp",
    href: "/product/hm2-lightweight-multi-angle-flashlight-mount",
  },
  {
    name: "Multi-tool Products",
    des: "Portable lighting products",
    img: "/image/home/category/6.webp",
    mImg: "/image/home/category/6-m.webp",
    href: "/product/nextorch-multi-tool-pocket-knife-pliers-folding-mini-portable-fold-outdoor-hunting-survival-rescue-multipurpose-repair-tool",
  },
  {
    name: "NEX Batons",
    des: "Portable lighting products",
    img: "/image/home/category/4.webp",
    mImg: "/image/home/category/4-m.webp",
    href: "/product/15-nex-walker-flashlight-baton",
  },
];
export default function ProductList() {
  const isMobile = useMobile();
  const [productList, setProdcutList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // 前100条精选数据的前四条
  const getProductListData = async () => {
    try {
      setLoading(true)
      const res = await getProductData({
        first: 100
      })
      if (res?.products?.edges) {
        const filterArr = res?.products?.edges.filter((item: any) => {
          return item?.node?.featured === true
        });
        // console.log(filterArr.slice(0,4));
        setProdcutList(filterArr.slice(0, 4))
      }
      console.log("productList----", res);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    // getProductListData()
  }, [])
  return (
    <div className="md:p-4">
      <ul className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
        {/* {
          loading ? new Array(4).fill(0).map(item => { return <Skeleton className="md:h-[696px] h-[528px] " /> })
            :
            productList?.length > 0 ?
              productList.map((item: any) => {
                return <>
                  <li
                    key={item.node.databaseId}
                    className="md:h-[696px] h-[528px] bg-no-repeat bg-center bg-cover relative"
                    style={{ backgroundImage: `url(${item.node.image?.sourceUrl})` }}
                  >
                    <Link href={`/product/${item.slug}`} className="absolute inset-0">
                      <div className="mt-12  mx-auto text-center flex flex-col">
                        <h4 className="font-bold text-[#101010]  lg:text-4xl text-2xl ">{item.node.productCategories?.edges[0]?.node?.name}</h4>
                        <p className=" text-[#333333] lg:text-2xl text-lg mb-4 max-md:mb-2 mt-1">{item.node.name}</p>
                        <div className="flex items-center gap-5 justify-center max-lg:hidden">
                          <Button size="lg" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                            READ MORE
                          </Button>
                        </div>
                        <div className="flex items-center gap-5 justify-center lg:hidden">
                          <Button size="default" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                            READ MORE
                          </Button>
                          <Button size="default" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                            BUY NOW
                          </Button>
                        </div>
                      </div>
                    </Link>
                  </li>
                </>
              })
              : ""
        } */}

        {category.map((item) => (
          <li
            key={item.name}
            className="md:h-[696px] h-[528px] bg-no-repeat bg-center bg-cover relative"
            style={{ backgroundImage: `url(${isMobile ? item.mImg : item.img})` }}
          >
            <Link href={item.href} className=" absolute inset-0">
              <div className="mt-12  mx-auto text-center flex flex-col">
                <h4 className="font-bold text-[#101010]  lg:text-4xl text-2xl ">{item.name}</h4>
                <p className=" text-[#333333] lg:text-2xl text-lg mb-4 max-md:mb-2 mt-1">{item.des}</p>
                <div className="flex items-center gap-5 justify-center max-lg:hidden">
                  <Button size="lg" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                    READ MORE
                  </Button>
                </div>
                <div className="flex items-center gap-5 justify-center lg:hidden">
                  <Button size="default" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                    READ MORE
                  </Button>
                  <Button size="default" variant="outline" className="border-[#333] text-[#333] bg-transparent">
                    BUY NOW
                  </Button>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
