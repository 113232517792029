"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { cn, getYoutubeLink } from "@/lib/utils";
import useMobile from "@/lib/hooks/useMobile";
import YoutubeVideo from "../Youtube/video";
import { nanoid } from "nanoid";

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

const speed = 1000;

const items = [
  {
    title: "Nextorch-NextGen-Final",
    img: "/image/home/banner2/1.webp",
    video: "/video/05-Nextorch-NextGen-Final_4K.mp4",
  },
  {
    title: "TA30C",
    img: "/image/home/banner2/2.webp",
    video: "/video/TA30C汽车碾压测试.mp4",
    href: "/product/nextorch-ta30c-set",
  },
  {
    title: "UT51",
    img: "/image/home/banner2/3.webp",
    video: "/video/UT51EN.mp4",
    href: "/product/nextorch-ut51-red-blue-flashing-safety-slap-bracelet",
  },
  {
    title: "WL60",
    img: "/image/home/banner2/4.webp",
    video: "/video/WL60 EN.mp4",
    href: "/product/wl60-green-aiming-laser-sight-with-white-light-led-illuminator",
  },
  {
    title: "TA20",
    img: "/image/home/banner2/5.webp",
    video: "/video/TA20专业的随身战术手电EN.mp4",
    href: "/product/nextorch-ta20-compact-tri-mode-tactical-flashlight-set",
  },
];

function Aaa({
  title,
  show,
  type,
  index,
  href,
}: {
  title: string;
  show: boolean;
  type: "next" | "pre";
  index: number;
  href: string;
}) {
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (show) {
      divRef.current!.style.display = "";
      // divRef.current!.className = "next-enter";
      type === "next" ? (divRef.current!.className = "next-enter") : (divRef.current!.className = "pre-enter");
    } else {
      type === "next" ? (divRef.current!.className = "next-leave") : (divRef.current!.className = "pre-leave");

      function animationend() {
        divRef.current!.style.display = "none";

        divRef.current?.removeEventListener("animationend", animationend);
      }
      divRef.current?.addEventListener("animationend", animationend);
    }
  }, [show]);

  return (
    <div ref={divRef}>
      <div
        className={cn("relative overflow-hidden pb-1 text-center text-[28px] font-bold text-white md:text-[44px]")}
        // style={{ transition: "all .6s ease" }}
      >
        {title}
      </div>
      <div className={cn("z-[3] flex w-full items-center justify-center gap-4 text-white max-md:text-sm")}>
        {/* <Link href="/" className=" flex items-center">
          <span className="hover:underline">Explore</span>
          <i className="ri-arrow-right-s-line text-lg pt-0.5"></i>
        </Link> */}
        {index !== 0 && (
          <Link href={`${href}`} className="flex items-center">
            <span className="hover:underline">Buy Now</span>
            <i className="ri-arrow-right-s-line pt-0.5 text-lg"></i>
          </Link>
        )}
      </div>
    </div>
  );
}

export default function HomeBanner2() {
  const [currentIndex, setCurrentIndex] = useState(0); // 用于存储当前index的状态
  const [activeIndex, setActiveIndex] = useState(0); // 用于存储当前index的状态

  // const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  useEffect(() => {
    setTimeout(() => {
      const videos = document.querySelectorAll<HTMLVideoElement>(".home-banner2-video");
      // console.log(videos);

      videos.forEach((el: any) => {
        if (el) {
          el.currentTime = 0;
          el.pause();
        }
      });

      videos[activeIndex]?.play();
    }, speed);
  }, [activeIndex]);

  const isMobile = useMobile();

  // useEffect(())

  const swiperRef = useRef<SwiperCore>();
  const swiper1Ref = useRef<SwiperCore>();
  const [type, setType] = useState<"next" | "pre">("next");

  useEffect(() => {
    // swiper1Ref.current?.slideTo(1);
    // swiperRef.current?.slideTo(1);
    // swiperRef.current?.slideTo(1, 0);
  }, [isMobile]);

  return (
    <div className="home-banner2 relative">
      <Swiper
        className="h-[480px] w-full xl:h-[600px] 2xl:h-[720px]"
        spaceBetween={isMobile ? 0 : 16}
        slidesPerView={isMobile ? 1 : 1.4}
        navigation={{
          // hideOnClick: true,
          nextEl: ".home-swiper2-button-next",
          prevEl: ".home-swiper2-button-prev",
        }}
        loop={true}
        centeredSlides
        pagination={{
          clickable: true,
          bulletActiveClass: "home-swiper-pagination-bullet-active",
          bulletClass: "home-swiper-pagination-bullet",
        }}
        onSwiper={(swiper) => {
          swiper1Ref.current = swiper;
        }}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        onSlideNextTransitionStart={() => {
          setType("next");
        }}
        onSlidePrevTransitionStart={() => {
          setType("pre");
        }}
        onSlideChange={(swiper) => {
          // if (swiper.activeIndex > activeIndex) {
          //   const speed = swiper.activeIndex - activeIndex;
          // swiperRef.current?.slideNext(1);
          // } else {
          //   const speed = activeIndex - swiper.activeIndex;
          //   swiperRef.current?.slidePrev();
          // }

          // const speed = swiper.activeIndex - activeIndex
          // swiper.activeIndex > activeIndex ? swiperRef.current?.slideNext() : swiperRef.current?.slidePrev();

          // if (swiper.activeIndex === 3) {
          //   swiperRef.current?.setTranslate(0);
          // } else {
          //   swiperRef.current?.setTranslate(speed);
          // }
          swiperRef.current?.slideTo(swiper.activeIndex);

          setActiveIndex(swiper.activeIndex);
          setCurrentIndex(swiper.realIndex);
        }}
        speed={speed}
      >
        {items.map((item, index) => (
          <SwiperSlide key={item.title}>
            <div className="relative h-full overflow-hidden text-white">
              {isMobile ? (
                <img src={item.img} className="absolute inset-0 h-full w-full object-cover" alt="" />
              ) : (
                <video
                  // ref={(el) => {
                  //   videoRefs.current[index] = el;
                  // }}
                  muted
                  loop
                  autoPlay={currentIndex === index ? true : false}
                  poster={item.img}
                  className="home-banner2-video absolute inset-0 h-full w-full object-cover"
                >
                  <source src={item.video} />
                </video>
              )}
            </div>
          </SwiperSlide>
        ))}

        <div className="absolute left-1/2 top-1/2 z-[2] h-12 w-[50vw] -translate-x-1/2 -translate-y-1/2 max-md:hidden">
          <div className="home-swiper2-button-prev absolute left-12 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full duration-300 hover:bg-[rgba(0,0,0,0.3)]">
            <i className="ri-arrow-left-s-line text-4xl text-white"></i>
          </div>
          <div className="home-swiper2-button-next absolute right-12 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full duration-300 hover:bg-[rgba(0,0,0,0.3)]">
            <i className="ri-arrow-right-s-line text-4xl text-white"></i>
          </div>
        </div>
      </Swiper>

      <div className="absolute left-1/2 top-12 z-[2] h-[130px] -translate-x-1/2 overflow-hidden">
        <Swiper
          className="absolute top-1/2 h-[130px] max-w-full -translate-y-1/2"
          // spaceBetween={10}
          slidesPerView={isMobile ? 1 : 2}
          navigation={{
            // hideOnClick: true,
            nextEl: ".home-swiper2-button-next",
            prevEl: ".home-swiper2-button-prev",
          }}
          centeredSlides
          loop={true}
          direction="vertical"
          effect={"fade"}
          // pagination={{
          //   clickable: true,
          //   bulletActiveClass: "home-swiper-pagination-bullet-active",
          //   bulletClass: "home-swiper-pagination-bullet",
          // }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => {
            setCurrentIndex(swiper.realIndex);
          }}
          speed={speed}
        >
          {items.map((item, index) => (
            <SwiperSlide key={item.title} className="relative flex flex-col items-center justify-center">
              <Aaa title={item.title} show={currentIndex === index} index={index} href={item.href ?? ""} type={type} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
